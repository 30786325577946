/* This makes the Mapbox Geocoder look more like Material UI */

.GeocoderInput .mapboxgl-ctrl-geocoder {
  box-shadow: none;
}

.GeocoderInput .mapboxgl-ctrl-geocoder::before {
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  pointer-events: none;
}

.GeocoderInput .mapboxgl-ctrl-geocoder--icon-search {
  left: 0px;
}

.GeocoderInput .mapboxgl-ctrl-geocoder--input {
  padding: 6px 32px;
}
