#startDate {
  padding: 0;
}

#endDate {
  padding: 0;
}

.DateRangePickerInput_arrow {
  padding-left: 10px;
  padding-right: 10px;
}

.DateRangePickerInput {
  padding: 10px;
}
